.error-log-modal {
  height: 40vh;
  width: 100%;
  :global(.ag-header-cell-text) {
    text-transform: lowercase;
  }
  :global(.ag-header-cell-text::first-letter) {
    text-transform: uppercase;
  }
  :global(.ag-tabs.ag-menu.ag-focus-managed.ag-ltr.ag-popup-child) {
    display: block !important;
  }
  :global(.ag-cell-wrap-text span) {
    white-space: normal !important;
    padding: 10px 0px;
  }

  :global(.ag-cell-auto-height div:first-child) {
    height: auto !important;
  }

  :global(.csb-centered-cell) {
    justify-content: center;
  }

  :global(.wk-advanced-table.wk-striped-grey) {
    padding-left: 0;
    padding-right: 0;
    :global(.ag-row-odd) {
      background-color: #f6f6f6;
    }
    :global(.ag-row-hover) {
      background-color: #e6f2f9 !important;
    }
    :global(.ag-popup) {
      position: absolute;
    }
    :global(.ag-tabs.ag-menu.ag-focus-managed.ag-ltr.ag-popup-child) {
      min-height: 100%;
      position: relative;
    }
    :global(
        .ag-set-filter-item-checkbox.ag-labeled.ag-label-align-right.ag-checkbox.ag-input-field
      ) {
      width: 100%;
      :global(.ag-input-field-label.ag-label.ag-checkbox-label) {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }
}

@media screen and (min-height: 500px) {
  .error-log-modal {
    height: 60vh;
  }
}

:global(.cg-fullscreen-open-end) {
  .error-log-modal {
    height: 100%;
  }
}
