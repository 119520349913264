.csb-table-overlay,
.csb-table-overlay-fixed {
  background: white;
  padding: 20px;
  display: grid;
  place-items: center;
  &-text {
    margin-bottom: 21px;
  }
  span {
    display: block;
  }
}

.csb-table-overlay-fixed {
  position: fixed;
  bottom: 40%;
  left: 42%;
}
