.top-positioned {
  position: absolute;
  top: -24px;
  right: 0px;
}

.right-positioned {
  position: absolute;
  right: -40px;
}
