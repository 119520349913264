.csb-table {
  height: 100%;
  .csb-table__container {
    overflow: hidden;
    position: relative;
    height: 100%;
    width: 100%;
    padding: 0rem;
  }

  .csb-table__content {
    position: absolute;
    overflow: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    height: 100%;
    width: 100%;
  }

  .csb-table__top-border {
    position: sticky;
    background-color: #dadada;
    height: 1px;
    width: 100%;
    top: 0;
    z-index: 999;
  }
  :global(
      .ag-overlay-wrapper.ag-layout-auto-height.ag-overlay-loading-wrapper
    ) {
    padding-top: 1rem !important;
  }
  :global(
      .ag-cell.ag-cell-not-inline-editing.ag-cell-normal-height.ag-cell-value.ag-cell-focus
    ) {
    border-color: transparent;
  }
  :global(.ag-body-horizontal-scroll.ag-apple-scrollbar) {
    position: sticky !important;
    bottom: 0px !important;
  }
  :global(.ag-root-wrapper.ag-ltr.ag-layout-auto-height) {
    border-top: none !important;
    border-right: none;
    overflow: visible;
  }
  :global(.csb-visible-cell) {
    overflow: visible;
  }
  :global(.ag-header) {
    position: sticky;
    top: 0;
    z-index: 2;
  }
}
.wk-striped:global(.wk-advanced-table) {
  :global(.ag-row-odd) {
    background-color: #e6f2f9 !important;
  }
}
